import englishMessages from "ra-language-english";
const en = {
  ra: {
    ...englishMessages.ra,
    action: {
      ...englishMessages.ra.action,
    },
  },
  resources: {
    company: {
      name: "Company |||| Companies",
    },
    user: {
      name: "User |||| Users",
    },
    chart_of_account: {
      name: "Chart of Account |||| Chart of Accounts",
    },
    sales_area: {
      name: "Sales Area |||| Sales Areas",
    },
    customer_type: {
      name: "Customer Type |||| Customer Types",
    },
    supplier: {
      name: "Supplier |||| Suppliers",
    },
    brand: {
      name: "Brand |||| Brands",
    },
    size: {
      name: "Size |||| Sizes",
    },
    kind: {
      name: "Kind |||| Kinds",
    },
    density: {
      name: "Density |||| Densities",
    },
    color: {
      name: "Color |||| Colors",
    },
    quality: {
      name: "Quality |||| Qualities",
    },
    product: {
      name: "Product |||| Products",
    },
    warehouse: {
      name: "Warehouse |||| Warehouses",
    },
    transport: {
      name: "Transport |||| Transports",
    },
    order: {
      name: "Order |||| Orders",
      fields: {
        code: "%{name}",
        date: "%{name}",
        color: "Color",
      },
    },
    sale: {
      name: "Sale |||| Sales",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    purchase: {
      name: "Purchase |||| Purchases",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    return: {
      name: "Return |||| Returns",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    customer: {
      name: "Customer |||| Customers",
      fields: {
        first_name: "First name",
        last_name: "Last name",
        dob: "Date of birth",
      },
    },
    price: {
      name: "Harga",
    },
    price_limit: {
      name: "Price limit |||| Price limits",
      fields: {
        customer_type: "Customer type",
        price: "Price limit",
        note: "Note",
      },
    },
    brand_price_addition: {
      name: "Brand Price Additions",
    },
    size_price_addition: {
      name: "Size Price Additions",
    },
    debt_payment: {
      name: "Pay",
    },
    debt_correction: {
      name: "Correction",
    },
    check_cashing: {
      name: "Check Cashing",
    },
    journal: {
      name: "Journal |||| Journals",
    },
    inventory_pending: {
      name: "Inventory Pending |||| Inventory Pendings",
    },
    close_book: {
      name: "Close Book |||| Close Books",
    },
    bad_debt: {
      name: "Bad Debt",
    },
    bad_debt_installment: {
      name: "Installment",
    },
    bad_debt_correction: {
      name: "Correction",
    },
    bad_debt_check_cashing: {
      name: "Check Cashing",
    },
    mutation_repacking: {
      name: "Repacking",
    },
    mutation_inventory_write_off: {
      name: "Inventory Write Off",
    },
    mutation_change_goods_code: {
      name: "Change Goods Code",
    },
    mutation_change_location: {
      name: "Change Location",
    },
    balance_sheets: {
      name: "Balance Sheets",
    },
    income_statements: {
      name: "Income Statements",
    },
    general_ledgers: {
      name: "General Ledgers",
    },
    analysis: {
      name: "Analysis",
    },
    account_receivables: {
      name: "Account Receivable |||| Account Receivables",
    },
    account_receivable_cards: {
      name: "Card |||| Cards",
    },
    account_receivable_agings: {
      name: "Age |||| Ages",
    },
    account_receivable_histories: {
      name: "History |||| Histories",
    },
    account_receivable_pcms: {
      name: "+ PCM |||| + PCMs",
    },
    account_receivable_pcm_cards: {
      name: "PCM Card",
    },
    account_receivable_pcm_ages: {
      name: "PCM Age",
    },
    account_payables: {
      name: "Account Payable |||| Account Payables",
    },
    account_payable_cards: {
      name: "Card |||| Cards",
    },
    account_payable_ages: {
      name: "Age |||| Ages",
    },
    account_payable_histories: {
      name: "History |||| Histories",
    },
    inventories: {
      name: "Inventory |||| Inventories",
    },
    inventory_cards: {
      name: "Card |||| Cards",
    },
    inventory_ages: {
      name: "Age |||| Ages",
    },
    inventory_histories: {
      name: "History |||| Histories",
    },
    all_transaction_journals: {
      name: "All Transaction Journals",
    },
    activity: {
      name: "Activity |||| Activities",
    },
    customer_debt_max_days: {
      name: "Customer Debt Max Day |||| Customer Debt Max Days",
    },
  },
  server: {
    not_allowed: "Not Allowed",
    does_not_match_code_symbol:
      "Selected value doesn't match symbol in the Code",
    sale_id_belongs_to_customer_id:
      "Sale code doesn't belong to the selected customer",
    is_zero_has_non_zero:
      "Return quantity 0 only for one SJ, either main or free one, those have to be included",
    is_main_n_free_exist:
      "Needs other SJ to be included, it's either the main or free one that is missing",
    cannot_cancel_pending: "Cannot cancel this pending item",
    forbidden_duplication: "Forbidden duplication",
    match_product_code_price_on_a_date:
      "Invalid price for product code and selected date",
    is_purchase_date_equal_or_less_than_purchase_return_date:
      "PJ not available on selected date",
    less_equal_remaining_stock: "Not enough stock",
    less_equal_purchased_quantity:
      "Total return quantity exceed received quantity",
    less_equal_total_purchase_order: "Total received exceed order quantity",
    equal_greater_than_item_date: "Cannot be less than the order date",
    unapproved_previous_sale_not_exist: "Approve previous sale first",
    unique_start_date_for_quality_filter_by:
      "Encounter duplicate start date for one of these quality",
    current_month_must_be_greater: "Can only close past month",
    calculated_but_not_closed_yet: "Weird, calculated but not closed yet", // tiap akun sdh di hitung saldonya di monthly balance, tp status table close_books belum tutup
    is_book_open: "This period has been closed",
    is_allowed_to_be_parent: "This account already used as a child",
    date_is_not_greater:
      "Check the date, selected transaction didn't exist yet",
    close_previous_month_first: "Close previous month first",
    is_debit_equal_credit: "Debit amount must be equal with credit amount",
    less_equal_with_order_quantity_db:
      "Must be less or equal with remaining order quantity",
    either_debit_or_credit_is_zero: "Both debit and credit should not zero",
    cannot_use_parent_for_journal: "Cannot use parent account for journal",
    is_unapproved: "This is unapproved",
    need_exactly_three_files: "Need exactly three files",
    approved_this_transaction_first: "Approved this transaction first: %{code}",
    open_next_month_first: "Open the next period first",
    is_coa_a_child: "Don't use parent, pick a COA child instead",
    has_active_price: "Doesn't have active price",
    must_be_of_same_group: "Products must be of same group of kind",
    must_be_of_same_supplier:
      "Products must be of same supplier (important for AR age by supplier)",
    notes_receivable_for_giro: "Notes receivable for giro payment type",
    belongs_to_other_supplier: "This product belongs to other supplier",
    no_unapproved_rj: "There is unapproved RJ",
    no_unapproved_kp: "There is unapproved KP",
    no_unapproved_arp: "There is unapproved ARP",
    no_unapproved_cas: "There is unapproved CAS",
    no_unapproved_bdk: "There is unapproved BDK",
    no_unapproved_bdp: "There is unapproved BDP",
    no_unapproved_cbd: "There is unapproved CBD",
    no_uncashing_check: "There is uncashing check",
    required_if_repeat_print: "Reason is required",
    each_user_allowed_once: "Print invoice only allowed once for each user",
  },
  mine: {
    filter: {
      search_all: "Search all",
    },
    expand_menu: {
      purchase: "Purchases",
      sale: "Sales",
      order: "Orders",
      product_code: "Product Code",
      factory_debt: "factory debt",
      customer_debt: "customer debt",
      bad_debt: "Bad Debts",
      mutation: "Mutations",
      report: "Report",
      general_ledger: "General Ledger",
      account_receivables: "Account Receivables",
      account_payables: "Account Payables",
      inventories: "Inventories",
    },
    message: {
      approve: "Approve",
      unapprove: "Unapprove",
      cancel: "Cancel",
      close_book: "Close Book",
      open_book: "Open Book",
      approve_content: "Are you sure you want to approve this item?",
      unapprove_content: "Are you sure you want to unapprove this item?",
      cancel_content: "Are you sure you want to cancel this item?",
      close_book_content: "Are you sure you want to close this period?",
      open_book_content: "Are you sure you want to open this period?",
      transport_according_to: "Either total vehicle or total kg",
      percentage_value: "Use decimal value of persentage",
      // over_due_debt:
      //   "Except the customer has over due debt, this is only for unsettled sale, ",
      print_again:
        "This item has already been printed %{count} times, type the reason why you want to print this again",
      print_content: "Are you sure you want to print this item?",
    },
    dialog: {
      create_sale_order: "Create Sale Order",
      edit_sale_order: "Edit Sale Order",
      create_sale: "Create Sale",
      edit_sale: "Edit Sale",
      create_sale_return: "Create Sale Return",
      edit_sale_return: "Edit Sale Return",
      create_promotion: "Create Promotion",
      edit_promotion: "Edit Promotion",
      create_factory_debt_payment: "Create Factory Debt Payment",
      edit_factory_debt_payment: "Edit Factory Debt Payment",
      create_factory_debt_correction: "Create Factory Debt Correction",
      edit_factory_debt_correction: "Edit Factory Debt Correction",
      create_customer_debt_payment: "Create Customer Debt Payment",
      edit_customer_debt_payment: "Edit Customer Debt Payment",
      create_customer_debt_correction: "Create Customer Debt Correction",
      edit_customer_debt_correction: "Edit Customer Debt Correction",
      create_check_cashing: "Create Check Cashing",
      edit_check_cashing: "Edit Check Cashing",
      create_journal: "Create Journal",
      edit_journal: "Edit Journal",
      create_bad_debt: "Create Bad Debt",
      edit_bad_debt: "Edit Bad Debt",
      create_product: "Create Product",
      edit_product: "Edit Product",
      create_bad_debt_installment: "Create Bad Debt Installment",
      edit_bad_debt_installment: "Edit Bad Debt Installment",
      create_bad_debt_correction: "Create Bad Debt Correction",
      edit_bad_debt_correction: "Edit Bad Debt Correction",
      create_bad_debt_check_cashing: "Create Bad Debt Check Cashing",
      edit_bad_debt_check_cashing: "Edit Bad Debt Check Cashing",
      create_purchase_order: "Create Purchase Order",
      edit_purchase_order: "Edit Purchase Order",
      create_mutation_repacking: "Create Repacking",
      edit_mutation_repacking: "Edit Repacking",
      create_mutation_inventory_write_off: "Create Inventory Write Off",
      edit_mutation_inventory_write_off: "Edit Inventory Write Off",
      print_delivery_note: "Print Delivery Note",
      print: "Print",
      create_price: "Create Price",
      edit_price: "Edit Price",
      create_transport: "Create Transport",
      edit_transport: "Edit Transport",
      create_kind: "Create Kind",
      edit_kind: "Edit Kind",
      create_customer_debt_max_day: "Create Customer Debt Max Day",
      edit_customer_debt_max_day: "Edit Customer Debt Max Day",
      create_brand_price_addition: "Create Brand Price Addition",
      edit_brand_price_addition: "Edit Brand Price Addition",
      create_size_price_addition: "Create Size Price Addition",
      edit_size_price_addition: "Edit Size Price Addition",
    },
    notification: {
      "403_error": "Cannot update approved data",
      not_allowed: "Not Allowed",
      Not_Found: "Not found",
      Free_package_is_unedited:
        "Free package is unedited, edit the main one instead",
      cannot_delete_used_data: "Cannot delete used data",
      Internal_server_error: "Internal server error",
    },
    label: {
      account: "Account",
      amount: "Amount",
      code: "Code",
      company: "Company",
      correction: "Correction",
      customer: "Customer",
      credit: "Credit",
      create: "Create",
      date: "Date",
      debt: "Debt",
      edit: "Edit",
      cancel: "Cancel",
      cashing: "Cashing",
      check_number: "Check number",
      coa_code: "COA code",
      customer_debt: "Customer Debt",
      customer_debts: "Customer Debts",
      debit: "Debit",
      discount_per_box: "Discount / box",
      due_date: "Due date",
      end_date: "End date",
      end_code: "End code",
      journal: "Journal",
      name: "Name",
      note: "Note",
      order_quantity: "Order quantity",
      package: "Package",
      persentage: "Persentage",
      percentage_in_decimal: "Percentage in decimal",
      price_per_box: "Price / box",
      product_code: "Product code",
      promotions: "Promotions",
      promotion: "Promosi",
      purchase_order: "Purchase order",
      purchase_number: "Purchase number",
      received_quantity: "Received quantity",
      rejected: "Rejected",
      sales_number: "Sales number",
      sent_quantity: "Sent quantity",
      special_discount_per_box: "Special discount / box",
      start_date: "Start date",
      start_code: "Start code",
      total_correction: "Total correction",
      total_vehicle: "Total vehicle",
      transaction_date: "Transaction date",
      transport: "transport",
      type: "Type",
      settled: "Settled",
      unsettled: "Unsettled",
      sale_code: "Sale code",
      sale_item: "Sale item",
      warehouse: "Warehouse",
      return_quantity: "Return quantity",
      price: "Price",
      bad_debt: "Bad Debt",
      installment: "Installment",
      payment: "Payment",
      remaining_debt: "Remaining Debt",
      remain: "Remain",
      total_payment: "Total Payment",
      supplier: "Supplier",
      max_payment_days: "Max Payment Days",
      quality: "Quality",
      quantity: "Quantity",
      stocktake: "Stocktake",
      sample: "Sample",
      ref_number: "Ref. Number",
      cogs: "Cost of Goods Sold",
      kind: "Kind",
      active: "Active",
      operator_name: "Operator Name",
      warehouse_broke: "Warehouse Broke",
      transfer_between_depo: "Transfer Between Depo",
      warning: "Warning",
      reason: "Reason",
      cus_sup: "Customer/Supplier",
      brand: "Brand",
      size: "Size",
      motif: "Motif",
      density: "Density",
      color: "Color",
      buy_price: "Buy Price",
      sell_price: "Sell Price",
      transport_discount: "Transport Discount / box",
      cost: "Cost",
      cost_type: "Cost Type",
      capacity: "Capacity",
      plate: "Plate",
      plate_number: "Plate Number",
      sale_order: "Sale Order",
      balance: "Balance",
      return: "Return",
      sent: "Sent",
      previous: "Previous",
      report: "Report",
      transaction: "Transaction",
      general_ledger_detail: "General Ledger Detail",
      show_detail: "Show",
      current_balance: "Current",
      income_statement: "Income Statement",
      balance_sheet: "Balance Sheet",
      tax: "Tax",
      percentage: "Percentage",
      permission: "Permission",
      role: "Role",
      symbol: "Symbol",
      "13_chars": "13 characters",
      "10_chars": "10 characters e.g. G40SPW=LCR",
      loan_limit: "Loan Limit",
      loan_tenor: "Loan Tenor",
      loan_tenor_tolerance: "Loan Tenor Tolerance",
      pic: "PIC",
      address: "Address",
      closed: "Closed",
      month: "Month",
      year: "Year",
      period: "Period",
      closed_date: "Closed Date",
      open: "Open",
      close: "Close",
      nature: "Normal",
      class: "Class",
      parent: "Parent",
      delivery_note: "Ref. Number",
      order_no: "Order No.",
      created_at: "Created At",
      join_on: "Join On",
      customer_code: "Customer Code",
      customer_name: "Customer Name",
      supplier_code: "Supplier Code",
      supplier_name: "Supplier Name",
      initial_balance: "Initial Balance",
      final_balance: "Final Balance",
      total_day: "Total Day",
      dashboard_title: "👋 Welcome to the Acura",
      dashboard_subtitle:
        "This is the second generation of Acura, an accounting app developed by CJFI for its Depos (branches). Contact Depo Support Division if you need further assistant or IT Division if you find any tecnical problem. 😁",
      dashboard_contact_dp_support: "Contact Depo Support",
      dashboard_contact_it: "Contact IT",
      upload: "Upload",
      sales_area_first: "Make sure sales area menu has been filled",
      upload_two_file_customer_and_address:
        "Upload two files (customer and customer address) at once",
      upload_two_file_transport_and_its_detail:
        "Upload two files (transport and its detail) at once",
      upload_two_files: "Upload two files at once",
      upload_three_files: "Upload three files at once",
      initial: "Initial",
      other: "Other",
      final: "Final",
      according_to_cogs: "According to COGS",
      value_in: "Money In",
      value_out: "Money Out",
      other_value: "Other Money",
      initial_value: "Initial Money",
      final_value: "Final Money",
      net: "Net",
      gross: "Gross",
      value: "Value",
      box: "Box",
      due: "Due",
      gross_purchase: "Gross Purchase",
      gross_sale: "Gross Sale",
      net_purchase: "Net Purchase",
      net_sale: "Net Sale",
      goods_code: "Goods Code",
      day: "Day",
      age: "Age",
      approve: "Approve",
      unapprove: "Unapprove",
      account_receivable_net: "Account Receivable Net",
      received_date: "Received Date",
      print_invoice: "Print Invoice",
      payment_code: "Payment Code",
      effective_date: "Effective Date",
      sale_date: "Sale Date",
    },
    radio_btn: {
      return_warehouse: "Return Warehouse",
      return_broken: "Return Broken",
    },
    list_title: {
      factory_debt_payment: "Debt Payment",
      factory_debt_correction: "Debt Correction",
      customer_debt_payment: "Debt Payment",
      customer_debt_correction: "Debt Correction",
      purchase_order: "Purchase Order",
      purchase_return: "Purchase Return",
      sale_order: "Sale Order",
      sale_return: "Sale Return",
      check_cashing: "Check Cashing",
      journal: "Journal",
      bad_debt: "Bad Debt",
      bad_debt_installment: "Bad Debt Installment",
      bad_debt_correction: "Bad Debt Correction",
      bad_debt_check_cashing: "Bad Debt Check Cashing",
      mutation_repacking: "Repacking",
      mutation_inventory_write_off: "Inventory Write Off",
      all_transaction_journal: "All Transaction Journal",
      general_ledger: "General Ledger",
      inventory_pending: "Inventory Pending",
      account_receivable_card: "Account Receivable Card",
      account_receivable_aging: "Account Receivable Aging",
      account_receivable_history: "Account Receivable History",
      account_receivable_pcm: "Account Receivable + Pcm",
      account_receivable_pcm_card: "PCM Card",
      account_receivable_pcm_age: "PCM Age",
      account_payable_card: "Account Payable Card",
      account_payable_age: "Account Payable Age",
      account_payable_history: "Account Payable History",
      inventory_card: "Inventory Card",
      inventory_age: "Inventory Age",
      inventory_history: "Inventory History",
    },
    tbl_header: {
      payment: "Payment",
      journal: "Journal",
      check: "Check",
      approved: "Approved",
      date: "Date",
      due_date: "Due Date",
      remaining_debt: "Remaining Debt",
      transaction_date: "Transaction Date",
      description: "Description",
      debit: "Debit",
      credit: "Kredit",
      check_cashing_date: "Check Cashing Date",
      bank_name: "Bank Name",
      check_number: "Check Number",
      note: "Note",
      actions: "Actions",
      sale_code: "Sale Code",
      code: "Code",
      company: "Company",
      customer: "Customer",
      type: "Type",
      coa: "COA",
      coa_name: "COA Name",
      correction: "Correction",
      customer_debt_payment_code: "Debt Payment Code",
      bad_debt_installment_code: "Debt Installment Code",
      product_code: "Product Code",
      product_name: "Product Name",
      price: "Price",
      warehouse: "Warehouse",
      sent_qty: "Sent Qty",
      return_qty: "Return Qty",
      delivery_note: "Delivery Note",
      product: "Product",
      name: "Name",
      kind: "Kind",
      stock: "Stock",
      location: "Location",
      total_in: "Total In",
      total_out: "Total Out",
    },
    btn: {
      approve: "Approve",
    },
    tooltip: {
      total_inventory_write_off: "Value might be changed after approve",
    },
  },
};
export default en;
